import axios from "axios";

/**
 * Service to call misc endpoints
 */
const GeneralService = {
  /**
   * Get a list of statuses
   * @param id
   * @returns {*}
   */
  statuses() {
    return new Promise((resolve, reject) => {
      axios
        .get("status")
        .then(result => {
          resolve(result);
        })
        .catch(error => {
          if (error.response) {
            reject(error.response.data);
          } else {
            reject(new Error(error));
          }
        });
    });
  },

  /**
   * Get a list of activities
   * @param id
   * @returns {*}
   */
  activities() {
    return new Promise((resolve, reject) => {
      axios
        .get("activities/recent")
        .then(result => {
          resolve(result);
        })
        .catch(error => {
          if (error.response) {
            reject(error.response.data);
          } else {
            reject(new Error(error));
          }
        });
    });
  },

  /**
   * Get a list of sectors
   * @param id
   * @returns {*}
   */
  sectors() {
    return new Promise((resolve, reject) => {
      axios
        .get("company-sectors")
        .then(result => {
          resolve(result);
        })
        .catch(error => {
          if (error.response) {
            reject(error.response.data);
          } else {
            reject(new Error(error));
          }
        });
    });
  },

  /**
   * Get a list of countries
   * @param id
   * @returns {*}
   */
  countries() {
    return new Promise((resolve, reject) => {
      axios
        .get("countries")
        .then(result => {
          resolve(result);
        })
        .catch(error => {
          if (error.response) {
            reject(error.response.data);
          } else {
            reject(new Error(error));
          }
        });
    });
  },

  /**
   * Get a list of units
   * @param id
   * @returns {*}
   */
  units() {
    return new Promise((resolve, reject) => {
      axios
        .get("units")
        .then(result => {
          resolve(result);
        })
        .catch(error => {
          if (error.response) {
            reject(error.response.data);
          } else {
            reject(new Error(error));
          }
        });
    });
  },

  /**
   * Get a list of states by country
   * @param id
   * @returns {*}
   */
  statesByCountry(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`states/country/${id}`)
        .then(result => {
          resolve(result);
        })
        .catch(error => {
          if (error.response) {
            reject(error.response.data);
          } else {
            reject(new Error(error));
          }
        });
    });
  },

  /**
   * Get a list of cities by states
   * @param id
   * @returns {*}
   */
  citiesByState(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`cities/state/${id}`)
        .then(result => {
          resolve(result);
        })
        .catch(error => {
          if (error.response) {
            reject(error.response.data);
          } else {
            reject(new Error(error));
          }
        });
    });
  }
};

export default GeneralService;
