import axios from "axios";

/**
 * Service to call observation endpoints
 */
const ObservationService = {
  /**
   * Log a new observation
   * @param params
   * @returns {*}
   */
  create(params) {
    return new Promise((resolve, reject) => {
      axios
        .post("observations", params)
        .then(result => {
          resolve(result);
        })
        .catch(error => {
          if (error.response) {
            reject(error.response);
          } else {
            reject(new Error(error));
          }
        });
    });
  },

  /**
   * Get a single observation by ID
   * @param id
   * @returns {*}
   */
  get(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`observations/${id}`)
        .then(result => {
          resolve(result);
        })
        .catch(error => {
          if (error.response) {
            reject(error.response.data);
          } else {
            reject(new Error(error));
          }
        });
    });
  },

  /**
   * Get a list of observations by a slug
   * @param id
   * @returns {*}
   */
  all(slug) {
    return new Promise((resolve, reject) => {
      axios
        .get(`observations?${slug}`)
        .then(result => {
          resolve(result);
        })
        .catch(error => {
          if (error.response) {
            reject(error.response.data);
          } else {
            reject(new Error(error));
          }
        });
    });
  },

  /**
   * Get a list of recent observations
   * @param count
   * @returns {*}
   */
  recent(count) {
    return new Promise((resolve, reject) => {
      axios
        .get(`observations/recent/${count}`)
        .then(result => {
          resolve(result);
        })
        .catch(error => {
          if (error.response) {
            reject(error.response.data);
          } else {
            reject(new Error(error));
          }
        });
    });
  },

  /**
   * Update a single observation by ID
   * @param id
   * @returns {*}
   */
  update(id, params) {
    return new Promise((resolve, reject) => {
      axios
        .put(`observations/${id}`, params)
        .then(result => {
          resolve(result);
        })
        .catch(error => {
          if (error.response) {
            reject(error.response.data);
          } else {
            reject(new Error(error));
          }
        });
    });
  },

  /**
   * Update an observation evidence
   * @param id
   * @returns {*}
   */
  updateEvidence(id, params) {
    return new Promise((resolve, reject) => {
      axios
        .put(`observations/${id}/evidences`, params)
        .then(result => {
          resolve(result);
        })
        .catch(error => {
          if (error.response) {
            reject(error.response.data);
          } else {
            reject(new Error(error));
          }
        });
    });
  },

  /**
   * Delete a single observation by ID
   * @param id
   * @returns {*}
   */
  delete(id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`observations/${id}`)
        .then(result => {
          resolve(result);
        })
        .catch(error => {
          if (error.response) {
            reject(error.response.data);
          } else {
            reject(new Error(error));
          }
        });
    });
  },

  /**
   * Delete a single observation by ID
   * @param id
   * @returns {*}
   */
  deleteEvidence(id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`observation-evidences/${id}`)
        .then(result => {
          resolve(result);
        })
        .catch(error => {
          if (error.response) {
            reject(error.response.data);
          } else {
            reject(new Error(error));
          }
        });
    });
  }
};

export default ObservationService;
